import { PageLayout } from "@components";
import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";
import Margins from "@components/Margins/Margins";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import LabProtocolHero from "./_components/LabProtocolHero";
import * as styles from "./protocol.module.scss";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";

const cards: FeatureCardProps[] = [
	{
		title: "An All-in-One Protocol Hub",
		image: (
			<StaticImage
				src={"./_assets/protocols_image_1.png"}
				alt="Easily Manage and Submit Orders"
			/>
		),
		list: [
			"Create a central hub for all team protocols, making it clear who’s behind them and how they’re used.",
			"Put your recently-used protocols right at the top to speed up your workflow.",
			"See how protocols are being used so that you understand which ones are the most helpful and relevant.",
		],
	},
	{
		title: "Efficient Protocol Preview and Editing",
		image: (
			<StaticImage
				src={"./_assets/protocols_image_2.png"}
				alt="Collaborate for In-depth Order Tracking"
			/>
		),
		list: [
			"Easily preview any protocol so you can decide whether to edit or return.",
			"Navigate seamlessly back and forth between editing a protocol version and the main library .",
			"Minimize any mistakes by making sure you’re always in the right version.",
		],
		orientation: "right",
	},
	{
		title: "Smart Protocol Tagging and Filtering ",
		image: (
			<StaticImage
				src={"./_assets/protocols_image_3.png"}
				alt="Find Orders Quickly and Customize Workflows"
			/>
		),
		list: [
			"Keep things organized by giving each protocol a descriptive label or tag.",
			"Find what you need instantly via targeted searches based those labels.",
			"Simplify your research process by filtering protocols with the tags that matter the most.",
		],
	},
];

function LabProtocols() {
	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "lab-protocol" }}
		>
			<Margins>
				<LabProtocolHero />
				<FeatureCardLayout className={styles.cards}>
					{cards.map((card, index) => {
						return <FeatureCard {...card} key={index} />;
					})}
				</FeatureCardLayout>
				<JoinInstitutionsV2
					outerContainerClassName={styles.joinInstitutions}
				/>
			</Margins>
		</PageLayout>
	);
}

export default LabProtocols;
