import { Hero } from "@components/Hero/Hero";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const hero = {
	title: "A Central Protocol Hub for Smooth Collaboration",
	description:
		"Make protocol management a breeze with easy tracking, accessibility, and organization.",
	image: (
		<StaticImage
			src="../_assets/hero_protocols.png"
			alt="A Central Protocol Hub for Smooth Collaboration"
		/>
	),
};

const LabProtocolHero = () => {
	return <Hero {...hero} heroTextContainerSize="small" />;
};

export default LabProtocolHero;
